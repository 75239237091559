// Variables
$primaryColor: #4527a4;
$secondaryColor: #8344c5;

// Columns
@for $i from 1 through 6 {
	.columns-#{$i} {
		grid-template-columns: repeat(#{$i}, 1fr);
	}

	.cols-#{$i} {
		columns: #{$i};
	}
}

@media (max-width: 768px) {
	@for $i from 1 through 6 {
		.columns-tablet-#{$i} {
			grid-template-columns: repeat(#{$i}, 1fr);
		}

		.cols-tablet-#{$i} {
			columns: #{$i};
		}
	}
}

@media (max-width: 576px) {
	@for $i from 1 through 6 {
		.columns-mobile-#{$i} {
			grid-template-columns: repeat(#{$i}, 1fr);
		}

		.cols-mobile-#{$i} {
			columns: #{$i};
		}
	}
}

// Dashicon
.dashicons {
	font-family: dashicons !important;
}

// Inner Content
.apbInnerContent {
	h1 {
		font-size: 18px !important;
	}

	h2 {
		font-size: 17px !important;
	}

	h3 {
		font-size: 16px !important;
	}

	h4 {
		font-size: 15px !important;
	}

	h5 {
		font-size: 14px !important;
	}

	h6 {
		font-size: 14px !important;
	}

	p,
	span,
	div,
	a,
	button,
	mark {
		font-size: 15px !important;
	}

	.read-more,
	.wp-block-cover,
	.wp-block-image,
	img {
		display: none;
	}

	* {
		font-family: inherit !important;
	}
}

// Advanced Posts
.wp-block-ap-block-posts {
	overflow: hidden;

	* {
		box-sizing: border-box;
	}

	.fillContentHeight {
		&.apbDefault {
			display: flex;
			flex-direction: column;

			.apbText {
				flex: 1;
				display: flex;
				flex-direction: column;
			}
		}

		&.apbSideImage {
			.apbText {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
		}
	}

	.apbGridPosts {
		display: grid;

		.swiper-notification {
			display: none;
		}
	}

	.apbGrid1Posts {
		display: grid;
		grid-template-columns: repeat(4, 1fr);

		.apbPost:nth-child(4n + 1) {
			grid-column: span 2;
			grid-row: span 2;
		}

		.apbPost:nth-child(4n + 2),
		.apbPost:nth-child(4n + 3) {
			grid-column: span 1;
			grid-row: span 1;
		}

		.apbPost:nth-child(4n + 4) {
			grid-column: span 2;
			grid-row: span 1;
		}

		@media (max-width: 992px) {
			grid-template-columns: repeat(2, 1fr);

			.apbPost:nth-child(3n + 1) {
				grid-column: span 1;
				grid-row: span 2;
			}

			.apbPost:nth-child(3n + 2),
			.apbPost:nth-child(3n + 3) {
				grid-column: span 1;
				grid-row: span 1;
			}
		}

		@media (max-width: 576px) {
			grid-template-columns: repeat(1, 1fr);

			.apbPost:nth-child(2n + 1),
			.apbPost:nth-child(2n + 2) {
				grid-column: span 1;
				grid-row: span 1;
			}
		}

		.swiper-notification {
			display: none;
		}
	}

	.apbMasonryPosts {
		article {
			break-inside: avoid;
		}

		.swiper-notification {
			display: none;
		}

		.apbOverlayHalfContent {
			min-height: auto;
		}
	}

	.apbPagination {
		display: flex;
		align-items: center;
		justify-content: center;
		list-style-type: none;
		padding: 0;
		margin: 15px 0 0 0;

		.apbPagePrev,
		.apbPageNumber,
		.apbPageNext {
			border-radius: 3px;
			cursor: pointer;

			&.apbActivePage {
				cursor: default;
			}
		}

		.disabled {
			display: none;
		}
	}
}

.apbSliderPosts {
	position: relative;
	overflow: hidden;
	height: auto;

	.swiper-wrapper {
		height: auto;
		align-items: center;
	}

	.swiper-pagination {
		.swiper-pagination-bullet:focus {
			outline: 0;
		}
	}

	.apbOverlay:not(.apbOverlayBox) .apbText {
		width: -webkit-fill-available !important;
	}

	article {
		box-sizing: border-box;
	}

	@media (max-width: 576px) {

		.swiper-button-prev,
		.swiper-rtl .swiper-button-next {
			left: 0;

			&::after {
				font-size: 30px;
			}
		}

		.swiper-button-next,
		.swiper-rtl .swiper-button-prev {
			right: 0;

			&::after {
				font-size: 30px;
			}
		}
	}
}

.apbTickerPosts {
	>div {
		width: 100%;
	}
}

.apbPost {
	border-radius: 5px;
	overflow: hidden;
	box-sizing: border-box;

	.apbMeta {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 10px;

		>span {
			display: inline-flex;
			align-items: center;
			gap: 10px;

			a {
				color: inherit;
			}
		}

		img {
			width: 1em;
			height: 1em;
			object-fit: contain;
		}

		.dashicons {
			width: auto;
			height: auto;
			font-size: inherit;
		}

		.dashicons-admin-comments {
			transform: translate(0, 2.5px);
		}
	}

	.apbExcerpt {
		p {
			margin: 0 !important;
			font-size: inherit !important;
		}
	}

	.apbReadMore {
		&.bottom {
			margin-top: auto;
		}

		a {
			display: inline-block;
			transition: all .3s ease;
		}
	}

	a {
		color: inherit;
		text-decoration: none;
		display: inline-block;
		transition: all .3s ease;
		cursor: pointer;
	}

	.apbThumb {
		position: relative;
		text-align: center;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		margin: 0;
		height: 10px;
		padding-bottom: 60%;

		>a,
		img {
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
		}

		.apbThumbCats {
			position: absolute;
			left: 10px;
			bottom: 10px;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			gap: 5px;

			a {
				text-decoration: none !important;
				padding: 2px 10px;
				border-radius: 2px;

				&:hover {
					cursor: pointer;
				}
			}
		}
	}


	&.apbSideImage {
		grid-template-columns: 1fr 1fr;
		align-items: center;
		height: inherit;
		display: flex;

		&.leftImage {
			grid-template-columns: 1fr 1.3fr;
		}

		&.rightImage {
			grid-template-columns: 1.3fr 1fr;
		}

		&.grid {
			display: grid;
		}

		.apbThumb {
			padding-bottom: 0;
			height: 100% !important;
		}

		@media (max-width: 576px) {
			grid-template-columns: 1fr !important;
			height: auto !important;
			align-self: stretch !important;

			&.rightImage {
				.apbThumb {
					margin-top: auto;
				}
			}

			.apbThumb {
				width: 100%;
				height: 200px !important;
			}
		}
	}

	&.apbOverlay {
		position: relative;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		z-index: 0;

		&.apbOverlayHover {
			>div {
				opacity: 0;
				transition: all .3s ease-out !important;

				* {
					transition: all 0s ease !important;
					transition: color .3s ease !important;
				}
			}

			&:hover {
				>div {
					opacity: 1;
				}
			}
		}

		&.apbOverlayBox {
			padding: 10% 7.5%;

			&.swiper-slide {
				.apbText {
					padding-left: 0 !important;
					padding-right: 0 !important;
					width: 85% !important;
					min-height: auto;
					height: auto !important;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}
			}
		}

		&.apbOverlayHalfContent {
			min-height: 400px;

			.apbText {
				justify-content: flex-end;
			}
		}

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			z-index: -1;
		}

		.apbText {
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 100%;
			min-height: 100%;
		}
	}
}